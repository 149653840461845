.comingSoon{
    text-align: center;
    margin-top: 70px;
    font-size: 28px;
}
.categories{
    margin-top: 40px;
    display: flex;
    margin-left: -50px;
    margin-right: -50px;
}
.categoriesSmall{
    margin-left: -22px;
    margin-right: -22px;
    justify-content: space-between;
}
.categories li{
    font-family: var(--font-regular);
    font-size: 19px;
    line-height: 32px;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
    padding-right: 50px;
    padding-left: 50px;
}
.categoriesSmall li{
    padding-right: 22px;
    padding-left: 22px;
    flex: 1;
}
.categories li:hover,
.categoryActive{
    color: var(--yellow);
}
.categories li:after{
    content: '';
    border-right: 1px solid rgba(236, 185, 86, 0.5);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
.categories li:last-child:after{
    content: none;
}
.categories li:first-child{
    /*padding-left: 0;*/
}
.categories li:nth-child(2){
}
.categories li:last-child{
    /*padding-right: 0;*/
}
.categories .number{
    color: var(--yellow);
    font-size: 48px;
    line-height: 52px;
    font-family: var(--font-third);
}
.categories .caption{
    font-size: 18px;
    line-height: 27px;
    margin-top: 7px;
}
.imageList{
    border-radius: 24px;
    border: 1px solid rgba(236, 185, 86, 0.49);
    width: 100%;
    margin-top: 40px;
}
.button{
    margin-top: 60px;
}
.minContainer{
    margin-top: 40px;
}
.list{
    position: relative;
    margin-bottom: 7px;
}
.list:after{
    content: '';
    border-left: 1px solid rgba(236, 185, 86, 0.4);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
}
.list:first-child:after {
    content: none;
}
.value{
    font-size: 48px;
    line-height: 42px;
    color: var(--yellow);
    font-family: var(--font-third);
}
.label{
    font-size: 16px;
    line-height: 27px;
    margin-top: 7px;
    white-space: nowrap;
}

@media(max-width: 1199px){
    .categories{
        margin-left: -30px;
        margin-right: -30px;
    }
    .categories li{
        font-size: 19px;
        line-height: 32px;
        padding-right: 30px;
        padding-left: 30px;
    }
    .list:nth-child(3):after {
        content: none;
    }
}

@media(max-width: 899px){
    .list{
        text-align: center;
    }
    .button{
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media(max-width: 599px) {
    .categories {
        margin-left: 0;
        margin-right: 0;
        justify-content: center;
    }

    .categories li {
        font-size: 16px;
        line-height: 25px;
        padding-right: 10px;
        padding-left: 10px;
        text-align: center;
    }
}