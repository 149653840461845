.image{
    position: relative;
}
.image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.textContent{
    border: 1px solid var(--yellow);
}
.spacing{
    padding-left: 60px !important;
    padding-right: 60px !important;
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

@media(max-width: 899px){
    .image{
        display: none;
    }
    .spacing{
        padding-left: 30px !important;
        padding-right: 30px !important;
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }
}