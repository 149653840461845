.card{
    text-align: center;
    flex: 1;
    padding: 0 85px 30px;
    position: relative;
}
.card:first-child:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 80%;
    width: 1px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(236,185,86,1) 50%, rgba(253,248,237,0) 75%);
}
.image{
    margin-top: -150px;
    position: relative;
}
.image img{
    max-width: 100%;
}
.social{
    margin-top: 14px;
    min-height: 38px;
}
.title{
    color: var(--yellow);
    font-family: var(--font-secondary-black);
    font-size: 56px;
    line-height: 61px;
    margin-top: 16px;
    text-transform: capitalize;
}
.subtitle{
    font-size: 20px;
    line-height: 20px;
    margin-top: 10px;
    text-transform: capitalize;
    color: #FFF;
}
.text{
    font-size: 14px;
    line-height: 19px;
    margin-top: 30px;
}


@media(max-width: 899px) {
    .card{
        margin-top: 50px;
    }
    .card:first-child:after{
        content: '';
        position: absolute;
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
        top: inherit;
        bottom: 0;
        height: 1px;
        width: 80%;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(236,185,86,1) 50%, rgba(253,248,237,0) 100%);
    }
    .image{
        margin-top: 0;
    }
}