.card{
    padding: 27px 27px 67px;
    border: 1px solid rgba(236, 185, 86, 0.3);
    border-radius: 200px;
    text-align: center;
}
.image{
    position: relative;
}
.image img{
    width: 100%;
}
.title{
    margin-top: 25px;
    color: #FFF;
    font-size: 22px;
    line-height: 37px;
}
.text{
    margin-top: 12px;
    font-size: 13px;
    line-height: 19px;
    width: 77%;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width: 899px) {
    .card {
        border-radius: 500px;
        padding-bottom: 100px;
    }
    .title{
        font-size: 25px;
    }
    .text{
        font-size: 16px;
    }
}