.container{
    padding: 120px 0 0;
    position: relative;
}
.bkg{
    position: absolute;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    max-width: 100%;
}
.logo{
    display: block;
    text-align: center;
}
.logo img{
    display: inline-block;
}
.subscribe{
    border: 1px solid var(--yellow);
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    max-width: 471px;
    margin: 45px auto 49px;
}
.email{
    background: transparent;
    border: 0;
    font-size: 16px;
    line-height: 16px;
    flex: 1;
    outline: none;
    color: #FFF;
    height: 40px;
}
.button{
    background: transparent;
    border: 0;
    cursor: pointer;
}
.menu{
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu li{
    color: #FFF;
    font-size: 14px;
    line-height: 14px;
    text-transform: capitalize;
}
.menu li a{
    padding: 0 20px;
}
.social{
    margin-top: 45px;
}
.copyright{
    margin-top: 85px;
    border-top: 1px solid rgba(236, 185, 86, 0.2);
    padding: 15px 0 21px;
    font-size: 12px;
    line-height: 12px;
}

@media(max-width: 899px){
    .container {
        padding-top: 75px;
    }
}

@media(max-width: 756px){
    .menu {
        display: block;
        text-align: center;
    }
    .menu li{
        margin-bottom: 10px;
    }
    .copyright{
        text-align: center;
    }
}